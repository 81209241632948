import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthRequest } from 'Src/feature/auth-request/useAuthRequest';
import { useCurrentAuthRequest } from 'Src/feature/auth-request/useCurrentAuthRequest';
import { useLogger } from 'Src/feature/context-logger/useLogger';
import { useToast } from 'Src/feature/toasts/useToast';
import useRoute from 'Src/routes/useRoute';
import useDOMEventListener from 'Src/utils/useDOMEvtListener';

import { useTerminal } from './useTerminal';

const sectionId = 'TerminalUiChangeTracker';

declare global {
  export interface GlobalEventHandlersEventMap {
    'TerminalUiChangeTracker.autoLogOut.override': CustomEvent<{ isPause: boolean, reason: string}>;
  }
}

/**
 * TerminalUiChangeTracker
 * @description - Component that controls the Auto-logout logic.  Based on current terminal?.terminalSettings?.autoLogoutAfterSecs
 */
const TerminalUiChangeTracker: FC = () => {
  const log = useLogger().getSubLogger({ name: sectionId });
  const { t } = useTranslation();
  const { navigate, crumbs } = useRoute();
  const { currentRequest } = useCurrentAuthRequest();
  const toast = useToast();

  const authRequestAnswer = useAuthRequest.mutate.answer();
  const terminalLocation = useTerminal.query.get();

  const timerRef = useRef<NodeJS.Timeout>();

  const onTimerEnd = useCallback(() => {
    log.info('onTimerEnd()', 'logging out...');
    if (currentRequest && currentRequest?.id) {
      authRequestAnswer.mutate({
        authRequestId: currentRequest.id,
        outcome: 'Canceled',
      }, {
        onError: (error) => {
          toast.show.serviceError(error, { id: `${sectionId}.authRequest-cancel`, title: t('authRequest.errors.cancel') });
        },
      });
    }
    navigate(crumbs.pos.user.logout.path);
  }, [authRequestAnswer, crumbs.pos.user.logout.path, currentRequest, log, navigate, t, toast.show]);

  const updateTimer = useCallback((isPause?: boolean) => {
    if (!terminalLocation.data) return;
    if (terminalLocation.data.terminal.terminalSettings.autoLogoutAfterSecs === 0) return;
    clearTimeout(timerRef.current);
    if (!isPause) timerRef.current = setTimeout(onTimerEnd, terminalLocation.data.terminal.terminalSettings.autoLogoutAfterSecs * 1000);
  }, [onTimerEnd, terminalLocation.data]);

  useDOMEventListener('click', () => {
    updateTimer();
  });
  useDOMEventListener('touchstart', () => {
    updateTimer();
  });
  useDOMEventListener('TerminalUiChangeTracker.autoLogOut.override', (evt) => {
    updateTimer(evt.detail.isPause);
    log.info('TerminalUiChangeTracker.autoLogOut.override', { isPause: evt.detail.isPause, reason: evt.detail.reason });
  });

  useEffect(() => {
    updateTimer();
    return () => clearTimeout(timerRef.current);
  }, [updateTimer]);

  return <></>;
};

export { TerminalUiChangeTracker };
