import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN } from 'Src/stores/appEnv';

/**
 * Mixpanel Session Replay
 * 
 * Docs: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#session-replay
 * 
 * Mixpanel Session Replay provides video recordings of user sessions, which can be viewed
 * from an external dashboard. In order for this to work, the MIXPAEL_TOKEN environment
 * variable needs to exist. For production environments, this token is passed in through
 * CI workflows. If a developer needs to use Mixpanel locally, they will need to define
 * the MIXPANEL_TOKEN in their .env file.
 * 
*/

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN, {
    /**
     * Percentage of SDK initializations that will qualify
     * for replay data capture. A value of “1” = 1%.
     */
    record_sessions_percent: 35,
    /**
     * CSS class name or regular expression for elements which
     * will be replaced with an empty element of the same dimensions,
     * blocking all contents. The value set below represents common
     * industry block classes.
     */
    record_block_class: `${/^(mp-block|fs-exclude|rr-block)$/}`,
    /**
     * Duration of inactivity in milliseconds before ending a
     * contiguous replay. A new replay collection will start
     * when active again.
     */
    record_idle_timeout_ms: 1800000, // 30 minutes
    /**
     * CSS class name or regular expression for elements that 
     * will have their text contents masked. The value set below
     * represents common industry mask classes.
     */
    record_mask_text_class: `${/^(mp-mask|rr-mask)$/}`,
    /**
     * Maximum length of a single replay in milliseconds. Up to
     * 24 hours is supported. Once a replay has reached the maximum
     * length, a new one will begin.
     */
    record_max_ms: 86400000, // 24 hours
  });
}

const Mixpanel = {
  startRecording: () => {
    if (MIXPANEL_TOKEN) mixpanel.start_session_recording();
  },
  stopRecording: () => {
    if (MIXPANEL_TOKEN) mixpanel.stop_session_recording();
  },
};

export { Mixpanel };
