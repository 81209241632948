import React, { useEffect } from 'react';

const useMountedState = (): { isMounted: boolean; isUnmounted: boolean } => {
  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    get isMounted(): boolean {
      return isMounted.current;
    },
    get isUnmounted(): boolean {
      return !isMounted.current;
    },
  };
};

export default useMountedState;
